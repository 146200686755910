import React from 'react'
import { Card, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag, faPrescriptionBottle, faLayerGroup } from '@fortawesome/free-solid-svg-icons'

const AffilProductCard = ({ product, shopButtonTitle }) => {
  return (
    <Card className="mb-5">
      <Card.Img variant="top" src={product.thumb.filename} />
      <Card.Body>
        <Card.Title>{product.name}</Card.Title>
        <Badge><FontAwesomeIcon icon={faTag} color="#3771c8" aria-label="Brand label"/>&nbsp; {product.brand}</Badge>&nbsp;
        <Badge><FontAwesomeIcon icon={faLayerGroup} color="#3771c8" aria-label="Material label"/>&nbsp; {product.material}</Badge><br />
        <Badge><FontAwesomeIcon icon={faPrescriptionBottle} color="#3771c8" aria-label="Size label"/>&nbsp; {product.size}</Badge>
        <Card.Text>
          {/* <br />
          {product.desc} */}
        </Card.Text>
        <a  href={product.affilLink.url} target="_blank" rel="noreferrer">
          <button className="shop-button">{shopButtonTitle}</button>
        </a>
      </Card.Body>
    </Card>

  )
}
export default AffilProductCard
