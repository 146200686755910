import React from 'react'
import { Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEarthEurope, faShoppingBag } from '@fortawesome/free-solid-svg-icons'

const AffilBrandCard = ({ brand }) => {
  return (
    <div className="brand-card">
      <div className="brand-image" style={{backgroundImage: `url(${brand.thumb.filename})`}}></div>
      <div className="brand-text">
        <h2>{brand.name}</h2>
        <Badge><FontAwesomeIcon icon={faEarthEurope} color="#2a451a" aria-label="Country label"/>&nbsp; {brand.country}</Badge>&nbsp;<Badge><FontAwesomeIcon icon={faShoppingBag} color="#2a451a" aria-label="Partner label"/>&nbsp; {brand.partner}</Badge>
        <p style={{marginTop: "10px"}}>{brand.description}</p>
      </div>
    </div>

  )
}
export default AffilBrandCard
