import React, { useEffect, useState } from 'react'
import Layout from '../components/layout/layout'
import Footer from '../components/layout/footer'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import { Row, Container, Col } from 'react-bootstrap'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Select from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTag, faPrescriptionBottle, faLayerGroup, faPalette } from '@fortawesome/free-solid-svg-icons'
import AffilProductCard from '../components/affilStore/affilProductCard'
import AffilBrandCard from '../components/affilStore/affilBrandCard' 
import i18next from 'i18next'

export default function GetBottle({data}) {

  // l10n
  let storyPage
  let storyBrands = []
  let storyProducts = []

  function storyBrandsCs() {
    data.brandsCs.edges.forEach((brand) => {
      storyBrands.push({
        id: brand.node.id,
        content: JSON.parse(brand.node.content)
      })
    })
  }

  function storyBrandsEn() {
    data.brandsEn.edges.forEach((brand) => {
      storyBrands.push({
        id: brand.node.id,
        content: JSON.parse(brand.node.content)
      })
    })
  }

  function affilProductsCs() {
    data.productsCs.edges.forEach((product) => {
      storyProducts.push({
        id: product.node.id,
        content: JSON.parse(product.node.content)
      })
    })
  }

  function affilProductsEn() {
    data.productsEn.edges.forEach((product) => {
      storyProducts.push({
        id: product.node.id,
        content: JSON.parse(product.node.content)
      })
    })
  }

  if (i18next.language === 'cs') {
    storyPage = JSON.parse(data.page.edges[0].node.content)
    storyBrandsCs()
    affilProductsCs()
  } else {
    storyPage = JSON.parse(data.page.edges[1].node.content)
    storyBrandsEn()
    affilProductsEn()
  }

  // products list
  const [productsToShow, setProductsToShow] = useState([])
  const [productsPerPage, setProductsPerPage] = useState(10)
  
  const uniqueBrands = [...new Set(Object.values(storyProducts).reduce((a,b) => a.concat(b.content.brand), []))]
  const filteredBrands = uniqueBrands.map(o => ({ name: o }))

  const uniqueMaterials = [...new Set(Object.values(storyProducts).reduce((a,b) => a.concat(b.content.material), []))]
  const filteredMaterials = uniqueMaterials.map(o => ({ name: o }))

  const uniqueColors = [...new Set(Object.values(storyProducts).reduce((a,b) => a.concat(b.content.color), []))]
  const filteredColors = uniqueColors.map(o => ({ name: o }))

  const uniqueSizes = [...new Set(Object.values(storyProducts).reduce((a,b) => a.concat(b.content.size), []))]
  const filteredSizes = uniqueSizes.map(o => ({ name: o }))

  const uniqueCategories = [...new Set(Object.values(storyProducts).reduce((a,b) => a.concat(b.content.category), []))]
  const filteredCategories = uniqueCategories.map(o => ({ name: o }))

  // list of all products satisfing all the filters
  const [ filteredAffilProducts, setFilteredAffilProducts ] = useState([])

  // selected filter values
  const [ selectedBrand, setSelectedBrand] = useState()
  const [ selectedMaterial, setSelectedMaterial] = useState()
  const [ selectedColor, setSelectedColor] = useState()
  const [ selectedSize, setSelectedSize] = useState()
  const [ selectedCategory, setSelectedCategory] = useState()

  const filterByBrand = (filteredData) => {
    // Avoid filter for empty string
    if (!selectedBrand) {
      return filteredData
    }

    const filterBrands = filteredData.filter(
      (product) => product.content.brand.indexOf(selectedBrand.name) !== -1
    )

    return filterBrands
  }

  const filterByMaterial = (filteredData) => {
    // Avoid filter for empty string
    if (!selectedMaterial) {
      return filteredData
    }

    const filterMaterials = filteredData.filter(
      (product) => product.content.material.indexOf(selectedMaterial.name) !== -1
    )

    return filterMaterials
  }

  const filterByColor = (filteredData) => {
    // Avoid filter for empty string
    if (!selectedColor) {
      return filteredData
    }

    const filterColors = filteredData.filter(
      (product) => product.content.color.indexOf(selectedColor.name) !== -1
    )

    return filterColors
  }

  const filterBySize = (filteredData) => {
    // Avoid filter for empty string
    if (!selectedSize) {
      return filteredData
    }

    const filterSizes = filteredData.filter(
      (product) => product.content.size.indexOf(selectedSize.name) !== -1
    )

    return filterSizes
  }

  const filterByCategory = (filteredData) => {
    // Avoid filter for empty string
    if (!selectedCategory) {
      return filteredData
    }

    const filterCategories = filteredData.filter(
      (product) => product.content.category.indexOf(selectedCategory.name) !== -1
    )

    return filterCategories
  }

  // infinite product load
  function loopWithSlice(start, end) {
    const slicedProducts = filteredAffilProducts.slice(start, end)
    setProductsToShow(slicedProducts)
  }

  function handleShowMoreProducts() {
    setProductsPerPage(prevProductsPerPage => prevProductsPerPage + 5)
  }

  // conditional display of products
  const getProducts = () => {
    if (filteredAffilProducts.length !== productsToShow.length) {
      return <button className="shop-button" onClick={handleShowMoreProducts}>{storyPage.body[2].blocks[2].title}</button>
    } else if ((filteredAffilProducts.length === productsToShow.length) && productsToShow.length !== 0) {
      return
    } else {
      return <p><i>{storyPage.body[2].blocks[3].title}</i></p>
    }
  }

  useEffect(() => {

    var filteredData = filterByBrand(storyProducts)
    filteredData = filterByMaterial(filteredData)
    filteredData = filterByColor(filteredData)
    filteredData = filterBySize(filteredData)
    filteredData = filterByCategory(filteredData)
    setFilteredAffilProducts(filteredData)

  }, [selectedBrand, selectedMaterial, selectedColor, selectedSize, selectedCategory])

  useEffect(() => {

    loopWithSlice(0, productsPerPage)

  }, [filteredAffilProducts, productsPerPage])

  return (
    <Layout>
      <GatsbySeo
        titleTemplate={storyPage.title + ' | %s'}
      />
      <div className="main-container">
        <section>
          <div className="store-hero my-0 d-flex" style={{minHeight: 'calc(70vh - 259px)', color: 'white'}} fluid={+true} >
            <Container style={{margin: 'auto'}}>
              <Row className="px-3 px-md-6 justify-content-center text-center">
                <Col md={6} className="sm-text-center" style={{alignSelf: 'center'}} >
                  <h1><ReactMarkdown remarkPlugins={[gfm]} children={storyPage.body[0].title} /></h1>
                  <div style={{fontSize: '20px', lineHeight: '1.3'}}>
                    <ReactMarkdown remarkPlugins={[gfm]} children={storyPage.body[0].subtitle} />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <section>
          <Container className="col-sm-10">
            <Row className="pt-5 justify-content-center brand-list">
              <h1 className="pb-4">{storyPage.body[1].title}</h1>
              {storyBrands.map(brand => (
                <AffilBrandCard key={brand.id} brand={brand.content} />
              ))}
            </Row>
            <Row className="pt-3 pb-5">
              <h1 className="pb-2 text-center">{storyPage.body[2].title}</h1>
              <Col sm className="pt-3">
                <p style={{marginBottom: '0.5rem'}}><FontAwesomeIcon icon={faTag} aria-label="Brand label"/> {storyPage.body[2].blocks[0].blocks[0].title}</p>
                <Select 
                  options={filteredBrands}
                  getOptionLabel={(option)=>option.name}
                  getOptionValue={(option)=>option.name}
                  name="brands"
                  value={selectedBrand}
                  onChange={(value)=>setSelectedBrand(value)}
                  isClearable={true}
                />
              </Col>
              <Col sm className="pt-3">
                <p style={{marginBottom: '0.5rem'}}><FontAwesomeIcon icon={faLayerGroup} aria-label="Material label"/> {storyPage.body[2].blocks[0].blocks[1].title}</p>
                <Select 
                  options={filteredMaterials}
                  getOptionLabel={(option)=>option.name}
                  getOptionValue={(option)=>option.name}
                  name="materials"
                  value={selectedMaterial}
                  onChange={(value)=>setSelectedMaterial(value)}
                  isClearable={true}
                />
              </Col>
              <Col sm className="pt-3">
                <p style={{marginBottom: '0.5rem'}}><FontAwesomeIcon icon={faPalette} aria-label="Color label"/> {storyPage.body[2].blocks[0].blocks[2].title}</p>
                <Select 
                  options={filteredColors}
                  getOptionLabel={(option)=>option.name}
                  getOptionValue={(option)=>option.name}
                  name="colors"
                  value={selectedColor}
                  onChange={(value)=>setSelectedColor(value)}
                  isClearable={true}
                />
              </Col>
              <Col sm className="pt-3">
                <p style={{marginBottom: '0.5rem'}}><FontAwesomeIcon icon={faPrescriptionBottle} aria-label="Size label"/> {storyPage.body[2].blocks[0].blocks[3].title}</p>
                <Select 
                  options={filteredSizes}
                  getOptionLabel={(option)=>option.name}
                  getOptionValue={(option)=>option.name}
                  name="sizes"
                  value={selectedSize}
                  onChange={(value)=>setSelectedSize(value)}
                  isClearable={true}
                />
              </Col>
              {/* CATEGORY NOT RELEVANT AT THIS MOMENT 
              <Col sm className="pt-3">
                <p style={{marginBottom: '0.5rem'}}><FontAwesomeIcon icon={faWandSparkles} aria-label="Category label"/> Category</p>
                <Select 
                  options={filteredCategories}
                  getOptionLabel={(option)=>option.name}
                  getOptionValue={(option)=>option.name}
                  name="categories"
                  value={selectedCategory}
                  onChange={(value)=>setSelectedCategory(value)}
                  isClearable={true}
                />
              </Col> */}
            </Row>
            <Row className="pb-4 justify-content-center product-cards">
              {productsToShow.map(product => (
                <AffilProductCard key={product.id} product={product.content} shopButtonTitle={storyPage.body[2].blocks[1].title} />
              ))}
            </Row>
            <Row className="pb-4 justify-content-center product-cards">
              {getProducts()}
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query StoreQuery {
    page: allStoryblokEntry(filter: {slug: {eq: "get-a-bottle"}})  {
      edges {
        node {
          content
        }
      }
    }
    brandsCs: allStoryblokEntry(filter: {field_component: {eq: "brand"}, lang: {eq: "cs"}})  {
      edges {
        node {
          id
          content
        }
      }
    }
    brandsEn: allStoryblokEntry(filter: {field_component: {eq: "brand"}, lang: {eq: "default"}})  {
      edges {
        node {
          id
          content
        }
      }
    }
    productsCs: allStoryblokEntry(filter: {field_component: {eq: "product"}, lang: {eq: "cs"}})  {
      edges {
        node {
          id
          content
        }
      }
    }
    productsEn: allStoryblokEntry(filter: {field_component: {eq: "product"}, lang: {eq: "default"}})  {
      edges {
        node {
          id
          content
        }
      }
    }
  }
`
